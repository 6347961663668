import {
  MintEntityMeta,
  MintModel,
  MintProperty,
  MintResource,
  MintUserModel,
} from '@bryllyant/mint-ngx';
import { Type } from 'class-transformer';
import { IsBoolean, IsEnum, IsInt, IsString } from 'class-validator';
import { ClientUserType } from '../client-user/client-user.types';
import { ClientModel } from '../client.model';
import { ClientInvitationStatus } from './client-invitation.types';

@MintResource('/client-invitations')
export class ClientInvitationModel extends MintModel {
  @MintProperty()
  @IsString()
  override readonly uid: string;

  @MintProperty()
  @Type(() => ClientModel)
  readonly client: ClientModel;

  @MintProperty()
  @IsBoolean()
  readonly isNewClient: boolean;

  @MintProperty()
  @Type(() => MintUserModel)
  readonly invitedUser: MintUserModel;

  @MintProperty()
  @IsEnum(ClientUserType)
  readonly invitedUserType: ClientUserType;

  @MintProperty()
  @IsEnum(ClientInvitationStatus)
  readonly status: ClientInvitationStatus;

  @MintProperty()
  @IsString()
  readonly invitationCode: string | null;

  @MintProperty()
  @IsInt()
  readonly invitedCount: number | null;

  @MintProperty()
  @Type(() => Date)
  readonly invitedAt: Date | null;

  @MintProperty()
  @Type(() => Date)
  readonly resentAt: Date | null;

  @MintProperty()
  @Type(() => Date)
  readonly revokedAt: Date | null;

  @MintProperty()
  @Type(() => Date)
  readonly viewedAt: Date | null;

  @MintProperty()
  @Type(() => Date)
  readonly acceptedAt: Date | null;

  @MintProperty()
  @Type(() => Date)
  readonly registeredAt: Date | null;

  @MintProperty()
  @Type(() => Date)
  override readonly updatedAt: Date;

  @MintProperty()
  @Type(() => Date)
  override readonly createdAt: Date;

  @MintProperty()
  @Type(() => Date)
  readonly deletedAt: Date;

  @MintProperty()
  @Type(() => MintEntityMeta)
  override readonly _meta: MintEntityMeta;

  constructor(model?: Partial<ClientInvitationModel>) {
    super(model);
  }
}
