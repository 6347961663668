import { MintUserModel } from '@bryllyant/mint-ngx';
import { plainToInstance, Type } from 'class-transformer';
import { IsDefined, IsEnum } from 'class-validator';
import { ClientUserType } from '../client-user/client-user.types';
import { ClientModel } from '../client.model';

export class ClientInvitationDto {
  @Type(() => ClientModel)
  @IsDefined()
  readonly client: ClientModel;

  @IsDefined()
  readonly isNewClient: boolean;

  @Type(() => MintUserModel)
  @IsDefined()
  readonly invitedUser: MintUserModel;

  @IsEnum(ClientUserType)
  @IsDefined()
  readonly invitedUserType: ClientUserType;

  constructor(model?: Partial<ClientInvitationDto>) {
    if (model) {
      Object.assign(this, plainToInstance(ClientInvitationDto, model));
    }
  }
}
