<fynvana-loader
  [isLoading]="loadingProcesses > 0"
  [loadingText]="baseService.layoutState.globalLoadingMessage || ''"
></fynvana-loader>

<nz-layout class="{{ wrapperClasses }}">
  <!-- Start Header -->
  <nz-header #header>
    <fynvana-main-header
      [app]="app"
      [menuItems]="headerMenuItems"
      [buttonShown]="headerButtonShown"
      [buttonPosition]="headerButtonPosition"
      [hideMenu]="hideHeaderMenu"
      [enableSearch]="enableHeaderSearch"
      [enableColorThemeToggle]="enableHeaderThemeToggle"
      [homeMenuItem]="homeMenuItem"
      [avatarMenuItems]="avatarMenuItems"
      [enableContactUs]="enableHeaderContactUs"
      [contactMenuItem]="contactMenuItem"
      [isAdminLoggedInAsUser]="isAdminLoggedInAsUser"
    ></fynvana-main-header>
  </nz-header>
  <!-- End Header -->

  <!-- Start Content -->
  <nz-content
    class="content-container {{ determineColorThemeClass() }}"
    [ngClass]="{
      flex: sidebarMenuItems?.length
    }"
  >
    <fynvana-sidebar-nav
      *ngIf="sidebarMenuItems?.length"
      [app]="app"
      [navTitle]="sidebarNavTitle"
      [menuItems]="sidebarMenuItems ?? []"
    >
    </fynvana-sidebar-nav>
    <main
      class="content"
      [ngClass]="{ 'with-sidebar': sidebarMenuItems?.length }"
    >
      <ng-content></ng-content>
    </main>
  </nz-content>
  <!-- End Content -->

  <!-- Start Footer -->
  <nz-footer>
    <fynvana-main-footer
      [app]="app"
      [menuItems]="footerMenuItems"
      [socialMenuItems]="socialMenuItems"
      [enableMinimalFooter]="enableMinimalFooter"
      [homeMenuItem]="homeMenuItem"
    ></fynvana-main-footer>
  </nz-footer>
  <!-- End Footer -->
</nz-layout>
