import { plainToInstance } from 'class-transformer';
import { IsDefined, IsEnum, IsObject, IsOptional } from 'class-validator';
import { TaxpayerAuthorizationModel } from './taxpayer-authorization.model';
import { TaxpayerAuthorizationStatus } from './taxpayer-authorization.types';

export class TaxpayerAuthorizationStatusUpdateDto {
  @IsEnum(TaxpayerAuthorizationStatus)
  @IsDefined()
  readonly status: TaxpayerAuthorizationStatus;

  @IsObject()
  @IsOptional()
  readonly filter?: Partial<
    Pick<
      TaxpayerAuthorizationModel,
      'irsForm' | 'typeOfTax' | 'taxYear' | 'taxFormNumber' | 'taxPeriod'
    >
  >;

  constructor(model?: Partial<TaxpayerAuthorizationStatusUpdateDto>) {
    if (model) {
      Object.assign(
        this,
        plainToInstance(TaxpayerAuthorizationStatusUpdateDto, model),
      );
    }
  }
}
