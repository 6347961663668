import { Injectable } from '@angular/core';

import {
  MintAddressModel,
  MintAddressService,
  MintAddressType,
  MintAuthService,
  MintCacheService,
  MintHttpService,
  MintLogger,
  MintPhoneType,
  MintQueryFilter,
  MintQueryOperator,
  MintService,
  MintStorageService,
  MintStorageType,
  MintTypeUtil,
  MintUserModel,
} from '@bryllyant/mint-ngx';
import { lastValueFrom } from 'rxjs';
import { BusinessModel } from '../../business/business.model';
import { BusinessService } from '../../business/business.service';
import { IndividualModel } from '../../individual/individual.model';
import { IndividualService } from '../../individual/individual.service';
import { IrsFormSignerTitleMap } from '../../irs-form/irs-form-signer';
import { IrsFormSignerTitle } from '../../irs-form/irs-form.types';
import { TaxIdType, TaxpayerStorageKeys } from '../../main.types';
import { RelationshipType } from '../../relationship/relationship.types';
import { TaxPeriod } from '../../tax.types';
import { TaxpayerAuthorizationDto } from '../authorization/taxpayer-authorization.dto';
import { TaxpayerAuthorizationService } from '../authorization/taxpayer-authorization.service';
import { TaxpayerConsentModel } from '../consent/taxpayer-consent.model';
import { TaxpayerConsentService } from '../consent/taxpayer-consent.service';
import { TaxpayerRelationship } from '../taxpayer-relationship';
import { TaxpayerModel } from '../taxpayer.model';
import { TaxpayerService } from '../taxpayer.service';
import { TaxpayerType } from '../taxpayer.types';
import { TaxpayerOnboardingUtil } from './taxpayer-onboarding-util';
import { TaxpayerOnboardingModel } from './taxpayer-onboarding.model';
import {
  TaxpayerOnboardingBusinessContext,
  TaxpayerOnboardingConsentRequests,
  TaxpayerOnboardingStatus,
  TaxpayerOnboardingStep,
  TaxpayerOnboardingStepStatus,
  TaxpayerOnboardingType,
} from './taxpayer-onboarding.types';

const logger = MintLogger.getLogger('TaxpayerOnboardingService');

@Injectable({ providedIn: 'root' })
export class TaxpayerOnboardingService extends MintService<TaxpayerOnboardingModel> {
  constructor(
    private readonly httpService: MintHttpService,
    private readonly cacheService: MintCacheService,
    private readonly authService: MintAuthService,
    private readonly addressService: MintAddressService,
    private readonly taxpayerAuthorizationService: TaxpayerAuthorizationService,
    private readonly taxpayerConsentService: TaxpayerConsentService,
    private readonly taxpayerService: TaxpayerService,
    private readonly individualService: IndividualService,
    private readonly businessService: BusinessService,
    private readonly localStorage: MintStorageService,
  ) {
    super(
      httpService,
      cacheService,
      TaxpayerOnboardingModel,
      TaxpayerOnboardingModel._mintResourceName,
    );
  }

  async getByCurrentUser(
    consentId?: string | null,
  ): Promise<TaxpayerOnboardingModel> {
    const me = await this.authService.me();

    // A user can have multiple onboardings with different consentIds
    const filter = MintQueryFilter.from<TaxpayerOnboardingModel>([
      {
        key: 'user.uid',
        value: me.uid,
        operator: MintQueryOperator.Equals,
      },
    ]);

    if (consentId) {
      filter.addExpression({
        key: 'consentId',
        operator: MintQueryOperator.Equals,
        value: consentId,
      });
    }

    return await this.findOne(filter);
  }

  async initOnboarding(args: {
    user: MintUserModel;
    consentId: string | null | undefined;
  }): Promise<TaxpayerOnboardingModel> {
    const { user, consentId } = args;
    let type = TaxpayerOnboardingType.SelfRegistered;
    let consentRequests: TaxpayerOnboardingConsentRequests | undefined;

    if (consentId) {
      consentRequests = await this.getConsentRequests(consentId);
      logger.debug('initOnboarding() - consentRequests: ', consentRequests);

      const isIndividualInvitation =
        consentRequests.primary.taxpayer.type === TaxpayerType.Individual;

      const isBusinessInvitation =
        consentRequests.primary.taxpayer.type === TaxpayerType.Business;

      if (isIndividualInvitation) {
        type = consentRequests.businesses.length
          ? TaxpayerOnboardingType.InvitedIndividualWithBusinesses
          : TaxpayerOnboardingType.InvitedIndividual;
      } else if (isBusinessInvitation) {
        type = consentRequests.individuals.length
          ? TaxpayerOnboardingType.InvitedBusiness
          : TaxpayerOnboardingType.InvitedBusinessSignerOnly;
      }
      // TODO: case for spouse invite
    }

    let taxpayerOnboarding = await this.save(
      new TaxpayerOnboardingModel({
        user,
        type,
        status: TaxpayerOnboardingStatus.Started,
        consentId,
        context: {
          [TaxpayerOnboardingStep.PersonalDetails]: {
            status: TaxpayerOnboardingStepStatus.Current,
            firstName: user.firstName ?? '',
            lastName: user.lastName ?? '',
            [TaxpayerOnboardingStep.PersonalSsnAndDob]: {
              status: TaxpayerOnboardingStepStatus.Current,
            },
          },
        },
      }),
    );

    // map business names from consent requests to onboarding context
    if (consentRequests?.primary?.taxpayer?.type === TaxpayerType.Business) {
      taxpayerOnboarding = await this.updateContext({
        taxpayerOnboarding,
        step: TaxpayerOnboardingStep.BusinessDetails,
        subStep: TaxpayerOnboardingStep.BusinessNameAndType,
        businessIndex: 0,
        update: {
          name: consentRequests.primary.taxpayer.legalName,
        },
      });
    } else if (consentRequests?.businesses) {
      for (let i = 0; i < consentRequests.businesses.length; i++) {
        taxpayerOnboarding = await this.updateContext({
          taxpayerOnboarding,
          step: TaxpayerOnboardingStep.BusinessDetails,
          subStep: TaxpayerOnboardingStep.BusinessNameAndType,
          businessIndex: i,
          update: {
            name: consentRequests.businesses[i].taxpayer.legalName,
          },
        });
      }
    }

    return taxpayerOnboarding;
  }

  async updateContext(args: {
    taxpayerOnboarding: TaxpayerOnboardingModel;
    step: TaxpayerOnboardingStep;
    subStep?: TaxpayerOnboardingStep;
    update: object;
    businessIndex?: number;
  }): Promise<TaxpayerOnboardingModel> {
    const { taxpayerOnboarding, step, subStep, update } = args;
    let { businessIndex } = args;
    const updateRequest = { ...taxpayerOnboarding.context } as any;

    if (!updateRequest[step]) {
      updateRequest[step] = {};
    }

    if (step === TaxpayerOnboardingStep.BusinessDetails) {
      if (!updateRequest[step].businesses) {
        updateRequest[step].businesses = [];
      }

      if (businessIndex !== undefined) {
        if (!updateRequest[step].businesses[businessIndex]) {
          updateRequest[step].businesses[businessIndex] = {};
        }
      }

      // update business or business subStep at business index
      if (!subStep && businessIndex === undefined) {
        updateRequest[step] = { ...updateRequest[step], ...update };
      } else {
        businessIndex = businessIndex ?? 0;

        if (subStep) {
          updateRequest[step].businesses[businessIndex][subStep] = {
            ...(updateRequest[step].businesses[businessIndex][subStep] ?? {}),
            ...update,
          };
        } else {
          updateRequest[step].businesses[businessIndex] = {
            ...(updateRequest[step].businesses[businessIndex] ?? {}),
            ...update,
          };
        }
      }
    } else {
      // update any other step/subStep
      if (subStep) {
        updateRequest[step][subStep] = {
          ...(updateRequest[step][subStep] ?? {}),
          ...update,
        };
      } else {
        updateRequest[step] = {
          ...(updateRequest[step] ?? {}),
          ...update,
        };
      }
    }

    const taxpayerOnboardingUpdate = await this.save(
      new TaxpayerOnboardingModel({
        ...taxpayerOnboarding,
        context: { ...updateRequest },
      }),
    );

    logger.debug(
      'updateContext(): taxpayerOnboardingUpdate.context',
      taxpayerOnboardingUpdate.context,
    );

    return taxpayerOnboardingUpdate;
  }

  async setStepCurrent(
    taxpayerOnboarding: TaxpayerOnboardingModel,
    step: TaxpayerOnboardingStep,
    subStep?: TaxpayerOnboardingStep,
    businessIndex?: number,
  ): Promise<TaxpayerOnboardingModel> {
    const nextStepStatus = TaxpayerOnboardingUtil.getContextValue({
      taxpayerOnboarding,
      step,
      subStep,
      businessIndex,
      key: 'status',
    });

    // if next step status isn't already complete, set next step status to Current
    if (nextStepStatus !== TaxpayerOnboardingStepStatus.Complete) {
      taxpayerOnboarding = await this.updateContext({
        taxpayerOnboarding,
        step,
        subStep,
        businessIndex,
        update: { status: TaxpayerOnboardingStepStatus.Current },
      });
    }

    return taxpayerOnboarding;
  }

  async setStepComplete(
    taxpayerOnboarding: TaxpayerOnboardingModel,
    step: TaxpayerOnboardingStep,
    subStep?: TaxpayerOnboardingStep,
  ): Promise<TaxpayerOnboardingModel> {
    return await this.updateContext({
      taxpayerOnboarding,
      step,
      subStep,
      update: { status: TaxpayerOnboardingStepStatus.Complete },
    });
  }

  async getConsentRequests(
    consentId: string,
  ): Promise<TaxpayerOnboardingConsentRequests> {
    const consentRequests =
      await this.taxpayerConsentService.getRequest(consentId);

    return {
      primary: consentRequests[0],
      individuals: consentRequests.filter(
        (consentRequest, i) =>
          consentRequest.taxpayer.type === TaxpayerType.Individual && i > 0,
      ),
      businesses: consentRequests.filter(
        (consentRequest, i) =>
          consentRequest.taxpayer.type === TaxpayerType.Business && i > 0,
      ),
    };
  }

  async completeAndGrantAuthorizations(
    taxpayerOnboarding: TaxpayerOnboardingModel,
    acceptedConsent?: boolean,
  ) {
    const user = await this.authService.me();
    const userTaxpayer = await this.taxpayerService.findByUser(user);

    const forceAuthorizationError = await lastValueFrom(
      this.localStorage.getItem(
        TaxpayerStorageKeys.TaxpayerForceAuthorizationError,
        MintStorageType.Local,
      ),
    );

    const consentRequests = taxpayerOnboarding.consentId
      ? await this.getConsentRequests(taxpayerOnboarding.consentId)
      : ({} as TaxpayerOnboardingConsentRequests);

    const individualContext = TaxpayerOnboardingUtil.getContextValue({
      taxpayerOnboarding,
      step: TaxpayerOnboardingStep.PersonalDetails,
    });

    const personalAddressContext =
      individualContext?.[TaxpayerOnboardingStep.PersonalAddress];

    const personalAddress = await this.addressService.save(
      new MintAddressModel({
        type: MintAddressType.Residential,
        street: personalAddressContext.street,
        city: personalAddressContext.city,
        stateCode: personalAddressContext.stateCode,
        postalCode: personalAddressContext.postalCode,
        countryCode: personalAddressContext.countryCode,
      }),
    );

    let dto = new TaxpayerAuthorizationDto({
      forceTestError: forceAuthorizationError,
    });

    let relationships: TaxpayerRelationship[] = [];
    let primaryTaxpayer: TaxpayerModel;
    let primaryIndividual: IndividualModel;

    switch (taxpayerOnboarding.type) {
      // -- Self-registration -- //
      case TaxpayerOnboardingType.SelfRegistered:
        // update primary taxpayer & individual with data from onboarding
        primaryIndividual = await this.individualService.save(
          new IndividualModel({
            ...userTaxpayer.individual,
            email: user.email,
            firstName: user.firstName ?? '',
            lastName: user.lastName ?? '',
            taxId:
              individualContext?.[TaxpayerOnboardingStep.PersonalSsnAndDob].ssn,
            taxIdType: TaxIdType.SSN,
            address: new MintAddressModel({ uid: personalAddress.uid }),
            birthDate:
              individualContext?.[TaxpayerOnboardingStep.PersonalSsnAndDob]
                .birthDate,
          }),
        );

        primaryTaxpayer = await this.taxpayerService.save(
          new TaxpayerModel({
            ...userTaxpayer,
            firstName: user.firstName ?? '',
            lastName: user.lastName ?? '',
            taxId:
              individualContext?.[TaxpayerOnboardingStep.PersonalSsnAndDob].ssn,
            taxIdType: TaxIdType.SSN,
            individual: primaryIndividual,
          }),
        );

        // create or load relationships
        relationships = await this.buildIndividualBusinessRelationships(
          taxpayerOnboarding,
          primaryTaxpayer.individual,
        );

        // enter tax year data from onboarding, create dto
        dto = new TaxpayerAuthorizationDto({
          ...dto,
          taxpayer: primaryTaxpayer,
          taxPeriod: TaxPeriod.December,
          taxStartYear:
            +individualContext?.[TaxpayerOnboardingStep.PersonalTaxYears]
              .taxStartYear,
          taxEndYear:
            +individualContext?.[TaxpayerOnboardingStep.PersonalTaxYears]
              .taxEndYear,
          relationships,
        });

        break;

      // -- Invited Individual -- //
      case TaxpayerOnboardingType.InvitedIndividual:
      case TaxpayerOnboardingType.InvitedIndividualWithBusinesses:
        // update primary taxpayer & individual with data from onboarding
        primaryIndividual = await this.individualService.save(
          new IndividualModel({
            ...consentRequests.primary.taxpayer.individual,
            email: user.email,
            firstName: user.firstName ?? '',
            lastName: user.lastName ?? '',
            taxId:
              individualContext?.[TaxpayerOnboardingStep.PersonalSsnAndDob].ssn,
            taxIdType: TaxIdType.SSN,
            address: new MintAddressModel({ uid: personalAddress.uid }),
            birthDate:
              individualContext?.[TaxpayerOnboardingStep.PersonalSsnAndDob]
                .birthDate,
          }),
        );

        primaryTaxpayer = await this.taxpayerService.save(
          new TaxpayerModel({
            ...consentRequests.primary.taxpayer,
            firstName: user.firstName ?? '',
            lastName: user.lastName ?? '',
            taxId:
              individualContext?.[TaxpayerOnboardingStep.PersonalSsnAndDob].ssn,
            taxIdType: TaxIdType.SSN,
            individual: primaryIndividual,
          }),
        );

        // create or load relationships
        relationships = await this.buildIndividualBusinessRelationships(
          taxpayerOnboarding,
          primaryIndividual,
          consentRequests.businesses,
        );

        // enter tax year data from onboarding, create dto
        dto = new TaxpayerAuthorizationDto({
          ...dto,
          taxpayer: primaryTaxpayer,
          taxPeriod: TaxPeriod.December,
          taxStartYear:
            +individualContext?.[TaxpayerOnboardingStep.PersonalTaxYears]
              .taxStartYear,
          taxEndYear:
            +individualContext?.[TaxpayerOnboardingStep.PersonalTaxYears]
              .taxEndYear,
          relationships,
          clientConsentGranted: acceptedConsent,
          client: consentRequests.primary.client,
        });

        break;

      // -- Invited Business -- //
      case TaxpayerOnboardingType.InvitedBusiness:
      case TaxpayerOnboardingType.InvitedBusinessSignerOnly:
        if (!consentRequests.primary?.taxpayer?.business) {
          return;
        }

        // update primary taxpayer & individual with data from onboarding
        const business = await this.businessService.findByUid(
          consentRequests.primary.taxpayer.business.uid,
        );
        const signer = await this.individualService.save(
          new IndividualModel({
            ...business.signer,
            email: user.email,
            firstName: user.firstName ?? '',
            lastName: user.lastName ?? '',
            taxId:
              individualContext?.[TaxpayerOnboardingStep.PersonalSsnAndDob].ssn,
            taxIdType: TaxIdType.SSN,
            address: new MintAddressModel({ uid: personalAddress.uid }),
            birthDate:
              individualContext?.[TaxpayerOnboardingStep.PersonalSsnAndDob]
                .birthDate,
          }),
        );

        // if we have an owner AND authorized signer, make sure to update the individual taxpayer with data from onboarding
        if (consentRequests.individuals.length) {
          await this.taxpayerService.save(
            new TaxpayerModel({
              ...consentRequests.individuals[0].taxpayer,
              firstName: user.firstName ?? '',
              lastName: user.lastName ?? '',
              taxId:
                individualContext?.[TaxpayerOnboardingStep.PersonalSsnAndDob]
                  .ssn,
              taxIdType: TaxIdType.SSN,
              individual: signer,
            }),
          );
        }

        // load in business data from onboarding
        const businessContexts: TaxpayerOnboardingBusinessContext[] =
          TaxpayerOnboardingUtil.getContextValue({
            taxpayerOnboarding,
            step: TaxpayerOnboardingStep.BusinessDetails,
            key: 'businesses',
          });
        const businessContext = businessContexts[0];

        const { name, type, title, ein } =
          businessContext[TaxpayerOnboardingStep.BusinessNameAndType];
        const {
          // taxFormNumber,
          taxPeriod,
          // firstYearFiled,
          taxStartYear,
          taxEndYear,
        } = businessContext[TaxpayerOnboardingStep.BusinessTaxDetails];
        const {
          phoneNumber,
          street,
          city,
          stateCode,
          postalCode,
          countryCode,
        } = businessContext[TaxpayerOnboardingStep.BusinessAddressAndPhone];

        // make sure signer title is valid:
        if (!IrsFormSignerTitleMap.isValid(type, title as IrsFormSignerTitle)) {
          return Promise.reject(
            `Invalid signer title for business type for business ${name}`,
          );
        }

        // if (!isValidBusinessFormForType(type, taxFormNumber)) {
        //   return Promise.reject(
        //     `Invalid business form selected for ${name}. Please make sure the business form matches the business type.`,
        //   );
        // }

        // create business address and fill out business, taxpayer models with data from onboarding
        const primaryBusinessAddress = await this.addressService.save(
          new MintAddressModel({
            type: MintAddressType.Commercial,
            street,
            city,
            stateCode,
            postalCode,
            countryCode,
          }),
        );

        const primaryBusiness = await this.businessService.save(
          new BusinessModel({
            ...consentRequests.primary.taxpayer.business,
            uid:
              businessContext.uid ??
              consentRequests.primary?.taxpayer?.business.uid,
            signerTitle: title,
            legalName: name,
            address: new MintAddressModel({ uid: primaryBusinessAddress.uid }),
            type,
            taxId: ein,
            taxIdType: TaxIdType.EIN,
            phoneNumber: phoneNumber.phoneNumber,
            phoneType: MintPhoneType.Other,
            signer,
          }),
        );

        primaryTaxpayer = await this.taxpayerService.save(
          new TaxpayerModel({
            ...consentRequests.primary.taxpayer,
            taxId:
              individualContext?.[TaxpayerOnboardingStep.PersonalSsnAndDob].ssn,
            taxIdType: TaxIdType.SSN,
            business: primaryBusiness,
          }),
        );

        // create or load relationships
        relationships = await this.buildBusinessIndividualRelationships(
          taxpayerOnboarding,
          primaryBusiness,
          consentRequests.individuals,
        );

        // enter tax year data from onboarding, create dto
        dto = new TaxpayerAuthorizationDto({
          ...dto,
          taxpayer: primaryTaxpayer,
          client: consentRequests.primary.client,
          clientConsentGranted: acceptedConsent,
          taxStartYear: +taxStartYear,
          taxEndYear: +taxEndYear,
          taxPeriod,
          // taxFormNumber,
          relationships,
        });

        break;
      case TaxpayerOnboardingType.TaxpayerInvited:
        // TODO
        break;
    }

    logger.debug('completeAndGrantAuthorizations() - dto:', dto);
    await this.taxpayerAuthorizationService.grantAuthorizations(dto);

    // complete onboarding
    await this.save(
      new TaxpayerOnboardingModel({
        ...taxpayerOnboarding,
        status: TaxpayerOnboardingStatus.Completed,
      }),
    );
  }

  async buildIndividualBusinessRelationships(
    taxpayerOnboarding: TaxpayerOnboardingModel,
    individual: IndividualModel,
    businessConsentRequests?: TaxpayerConsentModel[],
  ) {
    const businessContexts: TaxpayerOnboardingBusinessContext[] =
      TaxpayerOnboardingUtil.getContextValue({
        taxpayerOnboarding,
        step: TaxpayerOnboardingStep.BusinessDetails,
        key: 'businesses',
      }) ?? [];

    const relationships: TaxpayerRelationship[] = [];

    // Create individual to business relationships
    await Promise.all(
      businessContexts.map(async (businessContext, i) => {
        const { name, type, title, ein } =
          businessContext[TaxpayerOnboardingStep.BusinessNameAndType];
        const {
          // taxFormNumber,
          taxPeriod,
          // firstYearFiled,
          taxStartYear,
          taxEndYear,
        } = businessContext[TaxpayerOnboardingStep.BusinessTaxDetails];
        const {
          phoneNumber,
          street,
          city,
          stateCode,
          postalCode,
          countryCode,
        } = businessContext[TaxpayerOnboardingStep.BusinessAddressAndPhone];

        // FIXME: What to do with phoneNumber and firstYearFiled?

        // make sure signer title is valid:
        if (!IrsFormSignerTitleMap.isValid(type, title as IrsFormSignerTitle)) {
          return Promise.reject(
            `Invalid signer title for business type for business ${name}`,
          );
        }

        // if (!isValidBusinessFormForType(type, taxFormNumber)) {
        //   return Promise.reject(
        //     `Invalid business form selected for ${name}. Please make sure the business form matches the business type.`,
        //   );
        // }

        const address = await this.addressService.save(
          new MintAddressModel({
            street,
            city,
            stateCode,
            postalCode,
            countryCode,
          }),
        );

        const business = await this.businessService.save(
          new BusinessModel({
            uid:
              businessContext.uid ??
              businessConsentRequests?.[i]?.taxpayer?.business?.uid,
            signerTitle: title,
            legalName: name,
            address: new MintAddressModel({ uid: address.uid }),
            type,
            taxId: ein,
            taxIdType: TaxIdType.EIN,
            signer: individual,
            phoneNumber: phoneNumber.phoneNumber,
            phoneType: MintPhoneType.Other,
          }),
        );

        await this.updateContext({
          taxpayerOnboarding,
          step: TaxpayerOnboardingStep.BusinessDetails,
          businessIndex: i,
          update: { uid: business.uid },
        });

        relationships.push(
          new TaxpayerRelationship({
            ...(businessConsentRequests?.[i]?.relationship ?? {}),
            subjectIndividual: individual,
            relatedBusiness: business,
            relationshipType:
              businessConsentRequests?.[i]?.relationship?.relationshipType ??
              RelationshipType.Owner,
            taxStartYear: MintTypeUtil.parseInteger(taxStartYear)!,
            taxEndYear: MintTypeUtil.parseInteger(taxEndYear)!,
            taxPeriod,
            // taxFormNumber,
          }),
        );
      }),
    );

    return relationships;
  }

  async buildBusinessIndividualRelationships(
    taxpayerOnboarding: TaxpayerOnboardingModel,
    business: BusinessModel,
    individualConsentRequests: TaxpayerConsentModel[],
  ): Promise<TaxpayerRelationship[]> {
    const relationships: TaxpayerRelationship[] = [];
    const individualContext = TaxpayerOnboardingUtil.getContextValue({
      taxpayerOnboarding,
      step: TaxpayerOnboardingStep.PersonalDetails,
    });

    individualConsentRequests.map((individualConsentRequest) => {
      const isSigner =
        individualConsentRequest?.relationship?.isAuthorizedSigner;

      const taxStartYear = isSigner
        ? +individualContext?.[TaxpayerOnboardingStep.PersonalTaxYears]
            .taxStartYear
        : individualConsentRequest.taxStartYear;

      const taxEndYear = isSigner
        ? +individualContext?.[TaxpayerOnboardingStep.PersonalTaxYears]
            .taxEndYear
        : individualConsentRequest.taxStartYear;

      relationships.push(
        new TaxpayerRelationship({
          ...(individualConsentRequest?.relationship ?? {}),
          subjectBusiness: business,
          relatedIndividual: individualConsentRequest.taxpayer.individual,
          relationshipType:
            individualConsentRequest?.relationship?.relationshipType ??
            RelationshipType.OwnedEntity,
          taxStartYear,
          taxEndYear,
          taxPeriod: individualConsentRequest.taxPeriod ?? TaxPeriod.December,
        }),
      );
    });

    return relationships;
  }
}
