import { MintError } from '@bryllyant/mint-ngx';
import { IsEnum, IsInt, IsOptional } from 'class-validator';
import { RelationshipModel } from '../relationship/relationship.model';
import { TaxPeriod } from '../tax.types';

// NOTE: each relationship defines a related entity to a subject taxpayer.
// for example: if the taxpayer has a spouse, in the relationship
// object, the taxpayer will be the subjectIndividual, the spouse
// will be the relatedIndividual and the relationshipType will be 'spouse'
//
// In a more complicated scenario where the taxpayer is a business, the
// relationship could include the owner of the business.  If that owner
// filed a joint return, then another relationship object will exist for
// the spouse of that owner.
//
// If the Relationship is bound by dates, then a validation check should be
// performed to ensure the tax years fall within the start and end dates
// of the relationship.

// export type BusinessForm =
//   | TaxFormNumber._1065 // Partnership
//   | TaxFormNumber._1120 // C- Corp
//   | TaxFormNumber._1120S // S-Corp
//   | TaxFormNumber._990 // Nonprofit
//   | TaxFormNumber._1041 // Estates and Trusts
//   | TaxFormNumber._1040; // Self-employed
//
// export const businessFormOptions: MintFormFieldOption[] = [
//   {
//     name: '1065',
//     value: TaxFormNumber._1065,
//   },
//   {
//     name: '1120',
//     value: TaxFormNumber._1120,
//   },
//   {
//     name: '1120S',
//     value: TaxFormNumber._1120S,
//   },
//   {
//     name: '990',
//     value: TaxFormNumber._990,
//   },
//   {
//     name: '1041',
//     value: TaxFormNumber._1041,
//   },
//   {
//     name: '1040',
//     value: TaxFormNumber._1040,
//   },
// ];
//
export class TaxpayerRelationship extends RelationshipModel {
  // required for TaxpayerAuthorization if the related entity is a business
  // not required for TaxpayerConsent
  // @MintProperty()
  // @IsEnum(TaxFormNumber)
  // @IsOptional()
  // readonly taxFormNumber: BusinessForm;

  @IsInt()
  @IsOptional()
  readonly taxStartYear: number;

  @IsInt()
  @IsOptional()
  readonly taxEndYear: number;

  @IsEnum(TaxPeriod)
  @IsOptional()
  readonly taxPeriod: TaxPeriod;

  constructor(model?: Partial<TaxpayerRelationship>) {
    super(model);

    if (this.taxStartYear > this.taxEndYear) {
      throw new MintError(
        'taxStartYear must be less than or equal to taxEndYear',
      );
    }

    // let businessForm: BusinessForm | null = null;
    //
    // if (this.taxFormNumber && this.relatedBusiness?.type) {
    //   switch (this.relatedBusiness.type) {
    //     case BusinessType.C_Corporation:
    //       businessForm = TaxFormNumber._1120;
    //       break;
    //
    //     case BusinessType.S_Corporation:
    //       businessForm = TaxFormNumber._1120S;
    //       break;
    //
    //     case BusinessType.GeneralPartnership:
    //     case BusinessType.LimitedPartnership:
    //     case BusinessType.LLC:
    //       businessForm = TaxFormNumber._1065;
    //       break;
    //
    //     case BusinessType.NonProfit:
    //       businessForm = TaxFormNumber._990;
    //       break;
    //
    //     case BusinessType.Trust:
    //       businessForm = TaxFormNumber._1041;
    //       break;
    //
    //     // case BusinessType.SelfEmployed:
    //     // case BusinessType.IndependentContractor:
    //     //   businessForm = TaxFormNumber._1040;
    //     //   break;
    //   }
    // }
    //
    // if (businessForm && businessForm !== this.taxFormNumber) {
    //   throw new MintError('Invalid taxFormNumber for business type.', {
    //     data: {
    //       business: this.relatedBusiness,
    //       taxFormNumber: this.taxFormNumber,
    //     },
    //   });
    // }
  }
}

// export const getBusinessFormForType = (type: BusinessType): TaxFormNumber => {
//   switch (type) {
//     case BusinessType.C_Corporation:
//       return TaxFormNumber._1120;
//
//     case BusinessType.S_Corporation:
//       return TaxFormNumber._1120S;
//
//     case BusinessType.GeneralPartnership:
//     case BusinessType.LimitedPartnership:
//     case BusinessType.LLC:
//       return TaxFormNumber._1065;
//
//     case BusinessType.NonProfit:
//       return TaxFormNumber._990;
//
//     case BusinessType.Trust:
//       return TaxFormNumber._1041;
//
//     case BusinessType.SelfEmployed:
//     case BusinessType.SoleProprietorship:
//     case BusinessType.IndependentContractor:
//       return TaxFormNumber._1040;
//
//     default:
//       throw new MintError('Invalid business type: ' + type);
//   }
// };
//
// export const isValidBusinessFormForType = (
//   type: BusinessType,
//   form: TaxFormNumber,
// ): boolean => {
//   return form === getBusinessFormForType(type);
// };
//
// export const businessTypeTaxFormOptions = (
//   type: BusinessType,
// ): MintFormFieldOption[] => {
//   switch (type) {
//     case BusinessType.C_Corporation:
//       return [{ name: '1120', value: TaxFormNumber._1120 }];
//
//     case BusinessType.S_Corporation:
//       return [{ name: '1120S', value: TaxFormNumber._1120S }];
//
//     case BusinessType.GeneralPartnership:
//     case BusinessType.LimitedPartnership:
//     case BusinessType.LLC:
//       return [{ name: '1065', value: TaxFormNumber._1065 }];
//
//     case BusinessType.NonProfit:
//       return [{ name: '990', value: TaxFormNumber._990 }];
//
//     case BusinessType.Trust:
//       return [{ name: '1041', value: TaxFormNumber._1041 }];
//
//     case BusinessType.SelfEmployed:
//     case BusinessType.IndependentContractor:
//       return [{ name: '1040', value: TaxFormNumber._1040 }];
//   }
//
//   return [];
// };
