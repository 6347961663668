import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  MintAuthService,
  MintCacheService,
  MintError,
  MintHttpService,
  MintLogger,
  MintQueryFilter,
  MintQueryOperator,
  MintService,
  MintUserModel,
} from '@bryllyant/mint-ngx';
import { BaseService } from '@fynvana/common';
import { plainToInstance } from 'class-transformer';
import { UserService } from '../../core/user/user.service';
import { TaxIdType } from '../main.types';
import { TaxpayerModel } from '../taxpayer/taxpayer.model';
import { TranscriptModel } from '../transcript/transcript.model';

import { ClientUserModel } from './client-user/client-user.model';
import { ClientUserService } from './client-user/client-user.service';
import { ClientUserType } from './client-user/client-user.types';
import { ClientModel } from './client.model';

const logger = MintLogger.getLogger('ClientService');

@Injectable({ providedIn: 'root' })
export class ClientService extends MintService<ClientModel> {
  private currentClient: ClientModel;

  constructor(
    private readonly baseService: BaseService,
    private readonly httpService: MintHttpService,
    private readonly cacheService: MintCacheService,
    private readonly authService: MintAuthService,
    private readonly userService: UserService,
    private readonly clientUserService: ClientUserService,
  ) {
    super(
      httpService,
      cacheService,
      ClientModel,
      ClientModel._mintResourceName,
    );
  }

  async findByTaxId(
    taxId: string,
    taxIdType = TaxIdType.EIN,
  ): Promise<ClientModel | null> {
    return this.findOne(
      MintQueryFilter.from([
        {
          key: 'business.taxId',
          operator: MintQueryOperator.Equals,
          value: taxId,
        },
        {
          key: 'business.taxIdType',
          operator: MintQueryOperator.Equals,
          value: taxIdType,
        },
      ]),
    );
  }

  async findByUser(user: MintUserModel | string): Promise<ClientModel | null> {
    const uid = typeof user === 'string' ? user : user.uid;
    const result = await this.clientUserService.findByUser(user);
    return result ? await this.findByUid(result.client.uid) : null;
  }

  async getCurrentClient(): Promise<ClientModel> {
    if (typeof this.currentClient === 'undefined') {
      const me = await this.authService.me();
      const client = await this.findByUser(me);

      if (!client) {
        void this.userService.logout().then();
        void this.baseService.router.navigateByUrl('/auth/login').then();

        throw new MintError(`You are not authorized to access this account.`, {
          status: HttpStatusCode.Forbidden,
        });
      }

      this.currentClient = client;
    }

    return this.currentClient;
  }

  // ':clientUid/transcripts/taxpayers/:taxpayerUid'
  async getTranscriptsForTaxpayer(
    client: ClientModel,
    taxpayer: TaxpayerModel,
  ): Promise<TranscriptModel[]> {
    const urlPath = `${this.urlPath}/${client.uid}/transcripts/taxpayers/${taxpayer.uid}`;

    try {
      const result = await this.httpService.get(urlPath);
      return plainToInstance(
        TranscriptModel,
        result,
      ) as unknown as TranscriptModel[];
    } catch (err) {
      logger.error('getTranscriptsForTaxpayer: error: ', err);

      throw new MintError('Error fetching transcripts for taxpayer', {
        data: {
          error: err,
        },
      });
    }
  }

  async setOwner(client: ClientModel, newOwnerUser: MintUserModel) {
    const currentClientUserOwner = await this.getOwner(client);

    if (currentClientUserOwner) {
      await this.clientUserService.save(
        new ClientUserModel({
          ...currentClientUserOwner,
          type: ClientUserType.Member,
        }),
      );
    }

    const existingClientUser = await this.clientUserService.findOne(
      MintQueryFilter.from([
        {
          key: 'client.uid',
          value: client.uid,
          operator: MintQueryOperator.Equals,
        },
        {
          key: 'user.uid',
          value: newOwnerUser.uid,
          operator: MintQueryOperator.Equals,
        },
      ]),
    );

    await this.clientUserService.save(
      new ClientUserModel({
        uid: existingClientUser?.uid,
        client,
        user: newOwnerUser,
        type: ClientUserType.Owner,
      }),
    );
  }

  async getOwner(client: ClientModel | string): Promise<ClientUserModel> {
    const uid = typeof client === 'string' ? client : client.uid;

    return await this.clientUserService.findOne(
      MintQueryFilter.from([
        {
          key: 'client.uid',
          value: uid,
          operator: MintQueryOperator.Equals,
        },
        {
          key: 'type',
          value: ClientUserType.Owner,
          operator: MintQueryOperator.Equals,
        },
      ]),
    );
  }
}
