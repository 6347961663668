import {
  MintAddressModel,
  MintEntityMeta,
  MintModel,
  MintProperty,
  MintResource,
} from '@bryllyant/mint-ngx';
import { Type } from 'class-transformer';
import {
  IsBoolean,
  IsEmail,
  IsEnum,
  IsPhoneNumber,
  IsString,
} from 'class-validator';
import { BusinessModel } from '../business/business.model';
import { AgentDesignation } from './agent.types';

@MintResource('/agents')
export class AgentModel extends MintModel {
  @MintProperty()
  @IsString()
  readonly uid: string;

  @MintProperty()
  @IsString()
  readonly slug: string;

  @MintProperty()
  @IsBoolean()
  readonly isDefault: boolean;

  @MintProperty()
  @Type(() => BusinessModel)
  readonly business: BusinessModel;

  @MintProperty()
  @Type(() => MintAddressModel)
  readonly address: MintAddressModel;

  @MintProperty()
  @IsString()
  readonly firstName: string;

  @MintProperty()
  @IsString()
  readonly lastName: string;

  @MintProperty()
  @IsString()
  readonly legalName: string;

  @MintProperty()
  @IsEmail()
  readonly email: string;

  @MintProperty()
  @IsPhoneNumber()
  readonly telephoneNumber: string;

  @MintProperty()
  @IsPhoneNumber()
  readonly faxNumber: string;

  @MintProperty()
  @IsEnum(AgentDesignation)
  readonly designation: AgentDesignation;

  @MintProperty()
  @IsString()
  readonly cafNumber: string;

  @MintProperty()
  @IsString()
  readonly ptin: string;

  @MintProperty()
  @IsString()
  readonly jurisdiction: string;

  @MintProperty()
  @IsString()
  readonly licenseNumber: string;

  @MintProperty()
  @Type(() => Date)
  readonly updatedAt: Date;

  @MintProperty()
  @Type(() => Date)
  readonly createdAt: Date;

  @MintProperty()
  @Type(() => Date)
  readonly deletedAt: Date;

  @MintProperty()
  @Type(() => MintEntityMeta)
  readonly _meta: MintEntityMeta;

  constructor(model?: Partial<AgentModel>) {
    super(model);
  }
}
