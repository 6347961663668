import { Injectable } from '@angular/core';

import {
  MintAccessRole,
  MintAuthChannel,
  MintAuthService,
  MintCacheService,
  MintHttpService,
  MintLogger,
  MintQueryFilter,
  MintQueryOperator,
  MintRegistrationDto,
  MintService,
  MintStorageService,
  MintStorageType,
  MintUserModel,
  MintUserRoleService,
  MintUserService,
  MintUserSettings,
} from '@bryllyant/mint-ngx';
import { lastValueFrom } from 'rxjs';
import { ClientUserService } from '../../main/client/client-user/client-user.service';
import { TaxpayerService } from '../../main/taxpayer/taxpayer.service';

const logger = MintLogger.getLogger('UserService');

export const ADMIN_LOGGED_IN_AS_USER_KEY = 'admin-logged-in-as-user';

@Injectable({ providedIn: 'root' })
export class UserService extends MintService<MintUserModel> {
  constructor(
    private readonly authService: MintAuthService,
    private readonly localStorage: MintStorageService,
    private readonly mintUserService: MintUserService,
    private readonly userRoleService: MintUserRoleService,
    protected readonly httpService: MintHttpService,
    protected readonly cacheService: MintCacheService,
    protected readonly taxpayerService: TaxpayerService,
    protected readonly clientUserService: ClientUserService,
  ) {
    super(
      httpService,
      cacheService,
      MintUserModel,
      MintUserModel._mintResourceName,
    );
  }

  async adminRegisterUser(
    payload: MintRegistrationDto & { isAdmin: boolean },
  ): Promise<MintUserModel> {
    return this.httpService.post(
      this.urlPath + '/admin-register-user',
      payload,
    );
  }

  async updateMfaSettingsEnabledChannels(
    user: MintUserModel,
    channel: MintAuthChannel,
    enabled: boolean,
    userSettings?: MintUserSettings,
  ): Promise<MintUserSettings> {
    if (!userSettings) {
      userSettings = await this.mintUserService.getSettings(user);
    }

    const disabledChannels = userSettings?.auth?.mfa?.disabledChannels ?? [];
    const mfaEnabled = userSettings?.auth?.mfa?.enabled;
    const newSettings: MintUserSettings = { ...userSettings };

    if (enabled) {
      const index = disabledChannels.findIndex((ch) => ch === channel);

      if (index >= 0) {
        disabledChannels.splice(index, 1);
        newSettings.auth.mfa.disabledChannels = disabledChannels;
      }

      if (!mfaEnabled) {
        newSettings.auth.mfa.enabled = true;
      }
    }

    if (!enabled) {
      if (!disabledChannels.includes(channel)) {
        disabledChannels.push(channel);
      }

      newSettings.auth.mfa.disabledChannels = disabledChannels;

      if (
        disabledChannels.includes(MintAuthChannel.Sms) &&
        disabledChannels.includes(MintAuthChannel.Email)
      ) {
        newSettings.auth.mfa.enabled = false;
      }
    }

    return await this.mintUserService.updateSettings(user, null, newSettings);
  }

  async logout(clearLocalStorage = true) {
    await this.authService.logout(clearLocalStorage);
    await lastValueFrom(
      this.localStorage.removeItem(
        ADMIN_LOGGED_IN_AS_USER_KEY,
        MintStorageType.Local,
      ),
    );
  }

  async getUserRoleMap(
    user: MintUserModel | MintUserModel[],
  ): Promise<Map<string, string[]>> {
    const users = Array.isArray(user) ? user : [user];

    const map: Map<string, string[]> = new Map();

    if (!users.length) {
      return map;
    }

    const userRoles = await this.userRoleService.findAll(
      MintQueryFilter.from([
        {
          key: 'user.uid',
          operator: MintQueryOperator.In,
          value: users.map((user) => user.uid),
        },
        {
          key: 'role.slug',
          operator: MintQueryOperator.In,
          value: [
            MintAccessRole.System,
            MintAccessRole.SuperAdmin,
            MintAccessRole.User,
          ],
        },
      ]),
    );

    for (const user of users) {
      const roles = [];
      const userRoleSlugs = userRoles
        .filter((userRole) => userRole.user.uid === user.uid)
        .map((userRole) => userRole.role.slug);

      if (
        userRoleSlugs.some((item: string) =>
          [MintAccessRole.System, MintAccessRole.SuperAdmin].includes(
            item as MintAccessRole,
          ),
        )
      ) {
        roles.push('Super Admin');
      }

      const taxpayer = await this.taxpayerService.findByUser(user.uid);
      if (taxpayer) {
        roles.push('Taxpayer');
      }

      const clientUser = await this.clientUserService.findByEmail(user.email);
      if (clientUser) {
        roles.push('Client');
      }

      map.set(user.uid, roles);
    }

    return map;
  }
}
