import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  MintCacheService,
  MintHttpService,
  MintLogger,
  MintPolicyConsentModel,
  MintPolicyConsentStatus,
  MintService,
} from '@bryllyant/mint-ngx';
import { PolicyConsentService } from '../../../core/policy-consent/policy-consent.service';
import { TaxpayerModel } from '../taxpayer.model';
import { TaxpayerConsentRequestDto } from './taxpayer-consent-request.dto';
import { TaxpayerConsentModel } from './taxpayer-consent.model';
import { TaxpayerConsentStatus } from './taxpayer-consent.types';

const logger = MintLogger.getLogger('TaxpayerConsentService');

@Injectable({ providedIn: 'root' })
export class TaxpayerConsentService extends MintService<TaxpayerConsentModel> {
  constructor(
    private readonly httpService: MintHttpService,
    private readonly cacheService: MintCacheService,
    private readonly policyConsentService: PolicyConsentService,
  ) {
    super(
      httpService,
      cacheService,
      TaxpayerConsentModel,
      TaxpayerConsentModel._mintResourceName,
    );
  }

  async createRequest(
    dto: TaxpayerConsentRequestDto,
  ): Promise<TaxpayerConsentModel[]> {
    const result = await this.httpService.post<TaxpayerConsentModel[]>(
      `${this.urlPath}/requests`,
      dto,
    );

    return this.toModelArray(result);
  }

  // id is base64 encoded as:
  // consent[0].uid|consent[1].uid|...
  async getRequest(encodedId: string): Promise<TaxpayerConsentModel[]> {
    try {
      const result = await this.httpService.get<TaxpayerConsentModel[]>(
        `${this.urlPath}/requests/${encodedId}`,
      );

      return this.toModelArray(result);
    } catch (err) {
      if ((err as any).status === HttpStatusCode.NotFound) {
        return [];
      }
      throw err;
    }
  }

  async getUserForRequest(encodedId: string): Promise<{ uid: string } | null> {
    try {
      return (await this.httpService.get<{ uid: string }>(
        `${this.urlPath}/requests/${encodedId}/user`,
      )) as { uid: string };
    } catch (err) {
      if ((err as any).status === HttpStatusCode.NotFound) {
        return null;
      }
      throw err;
    }
  }

  async revokeConsent(consent: TaxpayerConsentModel) {
    await this.save(
      new TaxpayerConsentModel({
        uid: consent.uid,
        status: TaxpayerConsentStatus.TaxpayerRevoked,
      }),
    );

    await this.policyConsentService.save(
      new MintPolicyConsentModel({
        uid: consent.policyConsent.uid,
        consentStatus: MintPolicyConsentStatus.NotGranted,
      }),
    );
  }

  async findByTaxpayer(
    taxpayer: string | TaxpayerModel,
  ): Promise<TaxpayerConsentModel[]> {
    const taxpayerUid = typeof taxpayer === 'string' ? taxpayer : taxpayer.uid;
    const result = await this.httpService.get<TaxpayerConsentModel[]>(
      `${this.urlPath}/taxpayers/${taxpayerUid}`,
    );

    return this.toModelArray(result);
  }
}
