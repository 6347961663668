import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  MintCacheService,
  MintHttpService,
  MintLogger,
  MintObject,
  MintService,
} from '@bryllyant/mint-ngx';
import { ClientInvitationDto } from './client-invitation.dto';
import { ClientInvitationModel } from './client-invitation.model';

const logger = MintLogger.getLogger('ClientInvitationService');

@Injectable({ providedIn: 'root' })
export class ClientInvitationService extends MintService<ClientInvitationModel> {
  constructor(
    private readonly httpService: MintHttpService,
    private readonly cacheService: MintCacheService,
  ) {
    super(
      httpService,
      cacheService,
      ClientInvitationModel,
      ClientInvitationModel._mintResourceName,
    );
  }

  async create(
    dto: ClientInvitationDto,
    urlPathVars?: MintObject,
    params?: MintObject,
  ): Promise<ClientInvitationModel> {
    return super.create(dto, urlPathVars, params);
  }

  async acceptInvitation(
    invitation: string | ClientInvitationModel,
  ): Promise<ClientInvitationModel> {
    const uid = typeof invitation === 'string' ? invitation : invitation.uid;

    return this.toModel(
      await this.httpService.patch<ClientInvitationModel>(
        `${this.urlPath}/${uid}/accept`,
        {},
      ),
    );
  }

  async declineInvitation(
    invitation: string | ClientInvitationModel,
  ): Promise<ClientInvitationModel> {
    const uid = typeof invitation === 'string' ? invitation : invitation.uid;

    return this.toModel(
      await this.httpService.patch<ClientInvitationModel>(
        `${this.urlPath}/${uid}/decline`,
        {},
      ),
    );
  }

  async revokeInvitation(
    invitation: string | ClientInvitationModel,
  ): Promise<ClientInvitationModel> {
    const uid = typeof invitation === 'string' ? invitation : invitation.uid;

    return this.toModel(
      await this.httpService.patch<ClientInvitationModel>(
        `${this.urlPath}/${uid}/revoke`,
        {},
      ),
    );
  }

  async getByInvitationCode(
    invitationCode: string,
  ): Promise<ClientInvitationModel | null> {
    try {
      return this.toModel(
        await this.httpService.get<ClientInvitationModel>(
          `${this.urlPath}/${invitationCode}`,
        ),
      );
    } catch (err) {
      if ((err as any)?.status === HttpStatusCode.NotFound) {
        return null;
      }
      throw err;
    }
  }
}
