import { DeepPartial, MintInputPhoneValue } from '@bryllyant/mint-ngx';
import { BusinessType } from '../../business/business.types';
import { IrsFormSignerTitle } from '../../irs-form/irs-form.types';
import { TaxPeriod } from '../../tax.types';
import { TaxpayerConsentModel } from '../consent/taxpayer-consent.model';

export enum TaxpayerOnboardingStatus {
  Started = 'started',
  Completed = 'completed',
  Exited = 'exited',
}

export enum TaxpayerOnboardingStepStatus {
  Complete = 'complete',
  Current = 'current',
  NotStarted = 'not-started',
}

export enum TaxpayerOnboardingStep {
  PersonalDetails = 'personal-details',
  PersonalSsnAndDob = 'personal-ssn-and-dob',
  PersonalAddress = 'personal-address',
  PersonalTaxYears = 'personal-tax-years',

  BusinessDetails = 'business-details',
  BusinessNameAndType = 'business-name-and-type',
  BusinessTaxDetails = 'business-tax-details',
  BusinessAddressAndPhone = 'business-address-and-phone',

  RequestSummary = 'request-summary',
  Consent = 'consent',
  SignDocuments = 'sign-documents',
}

export enum TaxpayerOnboardingType {
  SelfRegistered = 'self-registered',
  TaxpayerInvited = 'taxpayer-invited',
  InvitedIndividual = 'invited-individual',
  InvitedIndividualWithBusinesses = 'invited-individual-with-businesses',
  InvitedBusiness = 'invited-business',
  InvitedBusinessSignerOnly = 'invited-business-signer-only',
}

export interface TaxpayerOnboardingConsentRequests {
  primary: TaxpayerConsentModel;
  individuals: TaxpayerConsentModel[];
  businesses: TaxpayerConsentModel[];
}

export type TaxpayerOnboardingBusinessContext = {
  uid: string;
  status: TaxpayerOnboardingStepStatus;

  [TaxpayerOnboardingStep.BusinessNameAndType]: {
    name: string;
    type: BusinessType;
    title: IrsFormSignerTitle;
    ein: string;
    status: TaxpayerOnboardingStepStatus;
  };

  [TaxpayerOnboardingStep.BusinessTaxDetails]: {
    // taxFormNumber: BusinessForm;
    taxPeriod: TaxPeriod;
    // firstYearFiled: string;
    taxStartYear: string;
    taxEndYear: string;
    status: TaxpayerOnboardingStepStatus;
  };

  [TaxpayerOnboardingStep.BusinessAddressAndPhone]: {
    phoneNumber: MintInputPhoneValue;
    street: string;
    city: string;
    stateCode: string;
    postalCode: string;
    countryCode: string;
    status: TaxpayerOnboardingStepStatus;
  };
};

export type TaxpayerOnboardingPersonalContext = {
  status: TaxpayerOnboardingStepStatus;
  firstName: string;
  lastName: string;

  [TaxpayerOnboardingStep.PersonalSsnAndDob]: {
    ssn: string;
    birthDate: Date;
    status: TaxpayerOnboardingStepStatus;
  };

  [TaxpayerOnboardingStep.PersonalAddress]: {
    street: string;
    city: string;
    stateCode: string;
    postalCode: string;
    countryCode: string;
    status: TaxpayerOnboardingStepStatus;
  };

  [TaxpayerOnboardingStep.PersonalTaxYears]: {
    taxStartYear: string;
    taxEndYear: string;
    status: TaxpayerOnboardingStepStatus;
  };
};

export type TaxpayerOnboardingContext = DeepPartial<{
  [TaxpayerOnboardingStep.PersonalDetails]: TaxpayerOnboardingPersonalContext;

  [TaxpayerOnboardingStep.BusinessDetails]: {
    status: TaxpayerOnboardingStepStatus;
    businesses: TaxpayerOnboardingBusinessContext[];
  };

  [TaxpayerOnboardingStep.RequestSummary]: {
    status: TaxpayerOnboardingStepStatus;
  };

  [TaxpayerOnboardingStep.Consent]: {
    isAccepted: boolean;
    status: TaxpayerOnboardingStepStatus;
  };
}>;
