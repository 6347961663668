import { Injectable } from '@angular/core';

import {
  MintAuthService,
  MintCacheService,
  MintHttpService,
  MintLogger,
  MintService,
  MintUserModel,
} from '@bryllyant/mint-ngx';
import { BusinessModel } from '../business/business.model';
import { IndividualModel } from '../individual/individual.model';
import { IndividualService } from '../individual/individual.service';

import { TaxpayerModel } from './taxpayer.model';

const logger = MintLogger.getLogger('TaxpayerService');

@Injectable({ providedIn: 'root' })
export class TaxpayerService extends MintService<TaxpayerModel> {
  constructor(
    private readonly httpService: MintHttpService,
    private readonly cacheService: MintCacheService,
    private readonly authService: MintAuthService,
    private readonly individualService: IndividualService,
  ) {
    super(
      httpService,
      cacheService,
      TaxpayerModel,
      TaxpayerModel._mintResourceName,
    );
  }

  async findByUser(user: string | MintUserModel): Promise<TaxpayerModel> {
    const uid = typeof user === 'string' ? user : user.uid;
    const result = await this.httpService.get<TaxpayerModel>(
      `${this.urlPath}/users/${uid}`,
    );
    return this.toModel(result);
  }

  async me(): Promise<TaxpayerModel> {
    const me = await this.authService.me();
    return await this.findByUser(me);
  }

  async findByBusiness(
    business: string | BusinessModel,
  ): Promise<TaxpayerModel> {
    const uid = typeof business === 'string' ? business : business.uid;
    const result = await this.httpService.get<TaxpayerModel>(
      `${this.urlPath}/businesses/${uid}`,
    );
    return this.toModel(result);
  }

  async findByIndividual(
    individual: string | IndividualModel,
  ): Promise<TaxpayerModel> {
    const uid = typeof individual === 'string' ? individual : individual.uid;
    const result = await this.httpService.get<TaxpayerModel>(
      `${this.urlPath}/individuals/${uid}`,
    );
    return this.toModel(result);
  }

  async findIndividualForTaxpayer(
    taxpayer: string | TaxpayerModel,
  ): Promise<IndividualModel> {
    const uid = typeof taxpayer === 'string' ? taxpayer : taxpayer.uid;
    const result = await this.httpService.get<IndividualModel>(
      `${this.urlPath}/${uid}/individual`,
    );
    return this.individualService.toModel(result);
  }
}
