import { plainToInstance, Type } from 'class-transformer';
import { IsDefined, IsEnum } from 'class-validator';
import { RelationshipType } from '../../relationship/relationship.types';
import { TaxpayerModel } from '../taxpayer.model';

export class TaxpayerInvitationDto {
  @Type(() => TaxpayerModel)
  @IsDefined()
  readonly taxpayer: TaxpayerModel;

  @Type(() => TaxpayerModel)
  @IsDefined()
  readonly invitedTaxpayer: TaxpayerModel;

  @IsEnum(RelationshipType)
  @IsDefined()
  readonly relationshipType: RelationshipType;

  constructor(model?: Partial<TaxpayerInvitationDto>) {
    if (model) {
      Object.assign(this, plainToInstance(TaxpayerInvitationDto, model));
    }
  }
}
