import { AgentDesignation } from '../agent/agent.types';
import { TaxIdType } from '../main.types';
import { TaxFormNumber } from '../tax.types';
import { TaxpayerType } from '../taxpayer/taxpayer.types';

export enum IrsFormType {
  Form2848 = 'form-2848',
  Form8821 = 'form-8821',
}

export enum IrsFormStatus {
  FormCreated = 'form-created',
  // a newer form was filed (does not update irs-expired or irs-rejected)
  FormSuperseded = 'form-superseded',
  TaxpayerPendingSignature = 'taxpayer-pending-signature',
  TaxpayerSigned = 'taxpayer-signed',
  AgentPendingSignature = 'agent-pending-signature',
  AgentSigned = 'agent-signed',
  FormExecuted = 'form-executed',

  // 8821 does not expire but 2848 does
  IrsExpired = 'irs-expired',

  // Form has been uploaded to IRS
  IrsSubmitted = 'irs-submitted',

  // A check to fetch the transcripts was made but still not available
  IrsPending = 'irs-pending',

  IrsRejected = 'irs-rejected',
  IrsAccepted = 'irs-accepted',
}

export interface IrsFormFieldPosition {
  pageNumber: number;
  xPosition: number; // points
  yPosition: number; // points
  width: number; // points
  height: number; // points
}

export type IrsFormFieldIndex = '' | '1' | '2' | '3' | '4';

export type IrsFormPageIndex = '1' | '2';

export type IrsFormFieldName<
  Page extends IrsFormPageIndex,
  FieldName extends string,
  Index extends IrsFormFieldIndex = '',
> = `topmostSubform[0].Page${Page}[0].${FieldName}${Index}[0]`;

export interface IrsFormField<FieldName, ValueType = string> {
  value: ValueType;
  type?: 'text' | 'checkbox';
  fieldName: FieldName;
}

export interface IrsFormTaxPayer {
  type: TaxpayerType;
  legalName: string;
  address: string;
  taxId: string;
  taxIdType: TaxIdType;
  phoneNumber?: string;
  planNumber?: string;
}

export interface IrsFormAgent {
  // firstName: string;
  // lastName: string;
  legalName: string;
  email: string;
  address: string;
  phoneNumber: string;
  faxNumber: string;
  ptin: string;
  cafNumber: string;
  designation: AgentDesignation;
  jurisdiction: string;
  licenseNumber: string;
}

export interface IrsFormSigner {
  legalName: string;
  email: string;
  title?: string;
  taxpayerName?: string;
  docusignClientId?: string;
}

export interface IrsFormDocusignSigners {
  taxpayers: IrsFormSigner[];
  agents?: IrsFormSigner[];
}

// export interface IrsFormDocusignSigners {
//   taxpayers: Signer[];
//   agents?: Signer[];
// }
//
export enum IrsFormSignerTitle {
  Partner = 'Partner',
  LimitedPartner = 'Limited Partner',
  Director = 'Director',
  CAO = 'Chief Accounting Officer',
  CEO = 'Chief Executive Officer',
  COO = 'Chief Operations Officer',
  CFO = 'Chief Financial Officer',
  President = 'President',
  VicePresident = 'Vice President',
  Treasurer = 'Treasurer',
  AssistantTreasurer = 'Assistant Treasurer',
  // OnePercentShareholder = '1% Shareholder',
  Shareholder = 'Shareholder',
  Controller = 'Controller',
  ManagingMember = 'Managing Member',
  Owner = 'Owner',
  SoleProprietor = 'Sole Proprietor',
  Executor = 'Executor',
  Beneficiary = 'Beneficiary',
  Trustee = 'Trustee',
  Administrator = 'Administrator',
}

// 8821
export enum IrsFormTypeOfTax {
  Income = 'Income',
  Employment = 'Employment',
  Payroll = 'Payroll',
  Excise = 'Excise',
  Estate = 'Estate',
  Gift = 'Gift',
  CivilPenalty = 'Civil Penalty',
  Section4980H = 'Sec. 4980H Payments',
}

// 2848
export enum IrsFormDescriptionOfMatter {
  PLR = 'PLR',
  BBA = 'Centralized Partnership Audit Regime (BBA)',
  Gift = 'Gift',
  FOIA = 'FOIA',
  Excise = 'Excise',
  Income = 'Income',
  Payroll = 'Payroll',
  EstateTax = 'Estate Tax',
  Employment = 'Employment',
  EmployeePlan = 'Employee Plan',
  CivilPenalty = 'Civil Penalty',
  // Section4980H = 'Section 4980H Shared Responsibility Payment',
  Section4980H = 'Section 4980H',
  Whistleblower = 'Whistleblower',
  SeparateAssessment = 'Separate Assessment',
  InnocentSpouseRelief = 'Innocent Spouse Relief',
  PractitionerDiscipline = 'Practitioner Discipline',

  // Partnerships or S-Corp
  IncomeIncludingPassThroughItems = 'Income including pass-through items',
}

export interface IrsFormAuthorization {
  typeOfTax: IrsFormTypeOfTax | IrsFormTypeOfTax[];
  taxForms: TaxFormNumber[];
  years: string[];
  specificTaxMatters?: string;
}

export interface IrsFormSpecificDeletions {
  line1: string;
  line2: string;
}

export interface IrsFormParamValues {
  taxpayer: IrsFormTaxPayer;
  agents: IrsFormAgent[];
  authorizations: IrsFormAuthorization[];
  deletions: IrsFormSpecificDeletions;
  signer: IrsFormSigner;
}
