import { Injectable } from '@angular/core';
import {
  MintCacheService,
  MintHttpService,
  MintLogger,
  MintQueryFilter,
  MintQueryOperator,
  MintService,
} from '@bryllyant/mint-ngx';
import { TaxpayerModel } from '../taxpayer/taxpayer.model';
import { IrsFormEventDto } from './event/irs-form-event.dto';
import { IrsFormStatusDto } from './irs-form-status.dto';
import { IrsFormModel } from './irs-form.model';
import { IrsFormSigner, IrsFormStatus } from './irs-form.types';

const logger = MintLogger.getLogger('IrsFormService');

@Injectable({ providedIn: 'root' })
export class IrsFormService extends MintService<IrsFormModel> {
  private activeIrsFormStatuses = [
    IrsFormStatus.FormCreated,
    IrsFormStatus.TaxpayerPendingSignature,
    IrsFormStatus.TaxpayerSigned,
    IrsFormStatus.AgentPendingSignature,
    IrsFormStatus.AgentSigned,
    IrsFormStatus.FormExecuted,
    IrsFormStatus.IrsSubmitted,
    IrsFormStatus.IrsPending,
    IrsFormStatus.IrsAccepted,
  ];

  constructor(
    private readonly httpService: MintHttpService,
    private readonly cacheService: MintCacheService,
  ) {
    super(
      httpService,
      cacheService,
      IrsFormModel,
      IrsFormModel._mintResourceName,
    );
  }

  async getSigningUrl(
    irsForm: IrsFormModel,
    signer: IrsFormSigner,
  ): Promise<{ url: string }> {
    return await this.httpService.get<{ url: string }>(
      `${this.urlPath}/${irsForm.uid}/sign/${signer.docusignClientId}`,
    );
  }

  async updateStatus(
    irsForm: string | IrsFormModel,
    dto: IrsFormStatusDto,
  ): Promise<IrsFormModel> {
    const uid = typeof irsForm === 'string' ? irsForm : irsForm.uid;
    const url = `${this.urlPath}/${uid}/status`;

    const result = await this.httpService.patch<IrsFormModel>(url, dto);

    return this.toModel(result);
  }

  async addEvent(
    irsForm: string | IrsFormModel,
    dto: IrsFormEventDto,
  ): Promise<IrsFormModel> {
    const uid = typeof irsForm === 'string' ? irsForm : irsForm.uid;
    const url = `${this.urlPath}/${uid}/events`;

    if (!dto.irsForm) {
      dto = new IrsFormEventDto({
        ...dto,
        irsForm: new IrsFormModel({ uid: uid }),
      });
    }

    const result = await this.httpService.post<IrsFormModel>(url, dto);

    return this.toModel(result);
  }

  async findByTaxpayer(
    taxpayer: string | TaxpayerModel,
  ): Promise<IrsFormModel[]> {
    const result = await this.findAll(
      MintQueryFilter.from({
        key: 'taxpayer.uid',
        operator: MintQueryOperator.Equals,
        value: typeof taxpayer === 'string' ? taxpayer : taxpayer.uid,
      }),
    );
    return this.toModelArray(result);
  }

  async findLatestByTaxpayer(
    taxpayer: string | TaxpayerModel,
  ): Promise<IrsFormModel | null> {
    const result = await this.findAll(
      MintQueryFilter.from({
        key: 'taxpayer.uid',
        operator: MintQueryOperator.Equals,
        value: typeof taxpayer === 'string' ? taxpayer : taxpayer.uid,
      }),
    );

    const irsForm = result.length
      ? result.sort(
          (a, b) => b.generatedAt.getTime() - a.generatedAt.getTime(),
        )[0]
      : null;

    return irsForm ? this.toModel(irsForm) : null;
  }

  async processTranscriptRefresh(taxpayer?: string | TaxpayerModel): Promise<{
    id: number | string;
    name: string;
  }> {
    const url = `${this.urlPath}/process-transcript-refresh`;

    const taxpayerUid = taxpayer
      ? typeof taxpayer === 'string'
        ? taxpayer
        : taxpayer.uid
      : undefined;

    return await this.httpService.post<{ id: number | string; name: string }>(
      url,
      {
        taxpayerUid,
      },
    );
  }

  async processTranscriptAccess(taxpayer?: string | TaxpayerModel): Promise<{
    id: number | string;
    name: string;
  }> {
    const url = `${this.urlPath}/process-transcript-access`;

    const taxpayerUid = taxpayer
      ? typeof taxpayer === 'string'
        ? taxpayer
        : taxpayer.uid
      : undefined;

    return await this.httpService.post<{ id: number | string; name: string }>(
      url,
      {
        taxpayerUid,
      },
    );
  }
}
