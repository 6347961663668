import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MintLogger } from '@bryllyant/mint-ngx';
import { BaseController, BaseService } from '@fynvana/common';
import { lastValueFrom } from 'rxjs';

const logger = new MintLogger('AppComponent');

@Component({
  selector: 'fynvana-root',
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent extends BaseController implements OnInit {
  constructor(
    public baseService: BaseService,
    private readonly httpClient: HttpClient,
  ) {
    super(baseService);

    this.setSEO(
      {},
      {
        twitterImg: 'assets/seo/twitter-image.jpg',
        ogImg: 'assets/seo/og-image.jpg',
      },
    );
  }

  async ngOnInit() {
    this.baseService.seoService.initGoogleTagManager(
      this.baseService.config.analytics?.google?.trackingId,
    );

    await this.initApiVersion();
  }

  private async initApiVersion() {
    const apiVersionData = await lastValueFrom(
      this.httpClient.get<{ api_version: string; hash: string }>(
        `${this.baseService.config.api.baseUrl}version`,
      ),
    );

    this.baseService.config.api.apiVersion =
      apiVersionData?.api_version ?? 'dev';
  }
}
