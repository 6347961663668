import { HttpErrorResponse } from '@angular/common/http';

import { KeyValPairs } from '../types';

export class ParseUtil {
  static capitalizeFirst(str: string | null): string {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  static parseSsn(ssn: string, obfuscate = false): string {
    if (obfuscate) {
      return '***-***-' + ssn.substr(5);
    } else {
      return ssn.substr(0, 3) + '-' + ssn.substr(3, 2) + '-' + ssn.substr(5);
    }
  }

  static parseEin(ein: string): string {
    return `${ein.slice(0, 2)}-${ein.slice(-7)}`;
  }

  static strToTitleCase(str: string): string {
    return !str
      ? str
          .split('-')
          .map((s: string): string =>
            s.replace(/^\w/, (c: string): string => c.toUpperCase()),
          )
          .join(' ')
      : '--';
  }

  static slugToWords(str: string): string {
    return str.split('-').join(' ');
  }

  static wordsToSlug(str: string | undefined): string {
    if (!str || !str.length) {
      return '';
    }
    return str?.toLowerCase().replace(' ', '-');
  }

  static slugToTitlecase = (str: string | undefined): string => {
    if (!str || !str.length) {
      return '';
    }

    return str
      .split('-')
      .join(' ')
      .split(' ')
      .map((s: string): string =>
        s.replace(/^\w/, (c: string): string => c.toUpperCase()),
      )
      .join(' ')
      .split('.')
      .map((s: string): string =>
        s.replace(/^\w/, (c: string): string => c.toUpperCase()),
      )
      .join(' ')
      .split('_')
      .map((s: string): string =>
        s.replace(/^\w/, (c: string): string => c.toUpperCase()),
      )
      .join(' ');
  };

  static camelCaseToSlug(string: string): string {
    return string.replace(/([A-Z])/g, '-$1').toLowerCase();
  }

  static camelCaseToWords(string: string): string {
    return string.replace(/([A-Z])/g, ' $1');
  }

  static errorToString(err: HttpErrorResponse | string | any): string {
    let errorMessage = '';

    if (typeof err === 'string') {
      errorMessage = err;
    } else if (err?.error?.driverError?.detail) {
      errorMessage = err.error?.driverError?.detail;
    } else if (err?.error?.message) {
      errorMessage = err.error.message;
    } else if (err?.message) {
      errorMessage = err.message;
    }

    return errorMessage ?? 'An unexpected error occurred';
  }

  static nameValToKeyVals = (
    map: {
      name: string;
      value: string;
    }[],
  ): KeyValPairs => {
    const keyValPairs: { [key: string]: string } = {};

    for (const obj of map) {
      keyValPairs[obj.value] = obj.name;
    }

    return keyValPairs;
  };

  static truncateString = (
    text = '',
    maxLength: number,
    ellipsisPlace?: number, // defaults to end of string
  ): string => {
    const placeEllipsis = (text: string): string => {
      if (ellipsisPlace) {
        return `${text.slice(0, ellipsisPlace)}...${text.slice(ellipsisPlace)}`;
      }

      return `${text}...`;
    };

    return text.length > maxLength
      ? placeEllipsis(text.substring(0, maxLength))
      : text;
  };

  static isNumeric(value: any): boolean {
    return !isNaN(parseFloat(value as string)) && isFinite(value as number);
  }

  static formatUserFirstLast(user: { firstName: string; lastName: string }) {
    const { firstName, lastName } = user;

    if (!firstName && !lastName) {
      return '--';
    }

    const capitalize = (str: string | null) => ParseUtil.capitalizeFirst(str);
    return `${capitalize(firstName)} ${capitalize(lastName)}`;
  }
}
