import { Injectable } from '@angular/core';

import {
  MintCacheService,
  MintHttpService,
  MintInvitationService,
  MintLogger,
  MintQueryFilter,
  MintQueryOperator,
  MintService,
  MintUserModel,
} from '@bryllyant/mint-ngx';
import { Subject } from 'rxjs';

import { ClientUserModel } from './client-user.model';

const logger = MintLogger.getLogger('ClientUserService');

@Injectable({ providedIn: 'root' })
export class ClientUserService extends MintService<ClientUserModel> {
  clientUserCreatedSubject = new Subject<boolean | undefined>();
  clientUserUpdatedSubject = new Subject<boolean | undefined>();

  constructor(
    private readonly httpService: MintHttpService,
    private readonly cacheService: MintCacheService,
    private readonly invitationService: MintInvitationService,
  ) {
    super(
      httpService,
      cacheService,
      ClientUserModel,
      ClientUserModel._mintResourceName,
    );
  }

  // async invite(clientUser: ClientUserModel): Promise<MintInvitationModel> {
  //   try {
  //     const result = await this.httpService.post<MintInvitationModel>(
  //       `${this.urlPath}/invitations`,
  //       clientUser,
  //     );
  //     return this.invitationService.toModel(result);
  //   } catch (err) {
  //     throw new MintError(`Error creating Client invitation`, { data: err });
  //   }
  // }

  async findByEmail(email: string): Promise<ClientUserModel | null> {
    return await this.findOne(
      MintQueryFilter.from({
        key: 'user.email',
        operator: MintQueryOperator.Equals,
        value: email.trim().toLowerCase(),
      }),
    );
  }

  async findByUser(user: string | MintUserModel): Promise<ClientUserModel> {
    return await this.findOne(
      MintQueryFilter.from({
        key: 'user.uid',
        operator: MintQueryOperator.Equals,
        value: typeof user === 'string' ? user : user.uid,
      }),
    );
  }
}
