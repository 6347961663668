import { Injectable } from '@angular/core';

import {
  MintCacheService,
  MintHttpService,
  MintLogger,
  MintService,
} from '@bryllyant/mint-ngx';
import { TaxpayerModel } from '../taxpayer.model';
import { TaxpayerService } from '../taxpayer.service';
import { TaxpayerAuthorizationStatusUpdateDto } from './taxpayer-authorization-status-update.dto';
import { TaxpayerAuthorizationDto } from './taxpayer-authorization.dto';
import { TaxpayerAuthorizationModel } from './taxpayer-authorization.model';

const logger = MintLogger.getLogger('TaxpayerAuthorizationService');

@Injectable({ providedIn: 'root' })
export class TaxpayerAuthorizationService extends MintService<TaxpayerAuthorizationModel> {
  constructor(
    private readonly httpService: MintHttpService,
    private readonly cacheService: MintCacheService,
    private readonly taxpayerService: TaxpayerService,
  ) {
    super(
      httpService,
      cacheService,
      TaxpayerAuthorizationModel,
      TaxpayerAuthorizationModel._mintResourceName,
    );
  }

  async grantAuthorizations(
    dto: TaxpayerAuthorizationDto,
  ): Promise<{ status: 'processing' }> {
    return await this.httpService.post<{ status: 'processing' }>(
      `${this.urlPath}/grant`,
      dto,
    );
  }

  async updateTaxpayerStatus(
    taxpayer: string | TaxpayerModel,
    dto: TaxpayerAuthorizationStatusUpdateDto,
  ): Promise<{
    id: number | string;
    name: string;
  }> {
    const taxpayerUid = typeof taxpayer === 'string' ? taxpayer : taxpayer.uid;

    return await this.httpService.patch<{ id: number | string; name: string }>(
      `${this.urlPath}/taxpayers/${taxpayerUid}/status`,
      dto,
    );
  }
}
