import { ExtraOptions } from '@angular/router';

import {
  MintCacheStorageType,
  ModulePreloadingStrategyService,
} from '@bryllyant/mint-ngx';

// Storage Keys
export const COLOR_THEME_KEY = 'color-theme';

export class Config {
  static SUCCESS_DURATION = 2000;
  static ERROR_DURATION = 3500;

  static VIEWPORT_BREAKPOINT = {
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
  };

  static MOBILE_BREAKPOINT = this.VIEWPORT_BREAKPOINT.lg; // lg or under

  static ROUTE_OPTIONS: ExtraOptions = {
    useHash: false,
    initialNavigation: 'enabledBlocking',
    enableTracing: false,
    preloadingStrategy: ModulePreloadingStrategyService,
    paramsInheritanceStrategy: 'always',
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload',
  };

  /** Builds mint configs based on current environment */
  static getMintAppConfig(env: any): any {
    return {
      auth: {},
      common: {
        env: env.env,
        configs: env.configs,
        cacheType: MintCacheStorageType.MEMORY,
      },
    };
  }
}
