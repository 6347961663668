import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

import {
  MintAddressModel,
  MintConfig,
  MintConfigService,
  MintFormController,
  MintPlaceService,
  MintSessionService,
  MintStorageService,
  MintStorageType,
} from '@bryllyant/mint-ngx';
import { MintPlaceModel } from '@bryllyant/mint-ngx/core/geo/place/mint-place.model';

import { NzNotificationService } from 'ng-zorro-antd/notification';
import { lastValueFrom, Subject } from 'rxjs';
import { COLOR_THEME_KEY } from '../config';
import { SEOService } from '../services/seo.service';

import {
  AppIdentifier,
  ColorTheme,
  LayoutState,
  LogoutType,
  SearchableItem,
  ViewportSize,
} from '../types';
import { ListService } from './list.service';

@Injectable({ providedIn: 'root' })
export class BaseService {
  toggleLoading = new Subject<boolean>();

  // Events from layout component to communicate to child componenets
  searchTriggered = new Subject<string>();
  actionTriggered = new Subject<any>();
  colorThemeChanged = new Subject<ColorTheme>();
  layoutIsScrolled = new Subject<boolean>();
  layoutIsMobile = new Subject<boolean>();
  layoutViewportChanged = new Subject<ViewportSize>();

  layoutState: Partial<LayoutState> = {
    viewportSize: undefined,
    isMobile: undefined,
    isScrolled: undefined,
    isNavSideBarOpen: true,
    isMobileMenuOpen: undefined,
    colorTheme: ColorTheme.Light,
    globalLoadingMessage: '',
  };

  portalUrls = new Map<AppIdentifier, string>();

  searchDictionary: SearchableItem[] = [];

  notificationService: NzNotificationService;
  listService: ListService;
  seoService: SEOService;
  localStorage: MintStorageService;
  configService: MintConfigService;
  placeService: MintPlaceService;
  sessionService: MintSessionService;
  router: Router;
  config: MintConfig;

  constructor(public injector: Injector) {
    this.notificationService = this.injector.get(NzNotificationService);
    this.listService = this.injector.get(ListService);
    this.seoService = this.injector.get(SEOService);
    this.localStorage = this.injector.get(MintStorageService);
    this.sessionService = this.injector.get(MintSessionService);
    this.configService = this.injector.get(MintConfigService);
    this.placeService = this.injector.get(MintPlaceService);
    this.router = this.injector.get(Router);

    this.config = this.configService.getConfig();

    this.portalUrls.set(
      AppIdentifier.Website,
      this.config.websiteBaseUrl as string,
    );
    this.portalUrls.set(
      AppIdentifier.Admin,
      this.config.adminBaseUrl as string,
    );

    this.portalUrls.set(
      AppIdentifier.Developer,
      this.config.developerBaseUrl as string,
    );

    this.portalUrls.set(AppIdentifier.Docs, this.config.docsBaseUrl as string);

    this.portalUrls.set(
      AppIdentifier.Taxpayer,
      this.config.taxpayerBaseUrl as string,
    );

    this.portalUrls.set(
      AppIdentifier.Client,
      this.config.clientBaseUrl as string,
    );

    this.sessionService.tokenExpired$.subscribe(async (expired) => {
      if (expired) {
        await this.router.navigate(['/auth/logout'], {
          queryParams: {
            type: LogoutType.TokenExpired,
            redirect: this.router.routerState.snapshot.url,
          },
        });
      }
    });
  }

  setLayoutScrolled(val: boolean) {
    this.layoutState.isScrolled = val;
    this.layoutIsScrolled.next(val);
  }

  setLayoutIsMobile(val: boolean) {
    this.layoutState.isMobile = val;
    this.layoutIsMobile.next(val);
  }

  setLayoutViewport(val: ViewportSize) {
    this.layoutState.viewportSize = val;
    this.layoutViewportChanged.next(val);
  }

  async setColorTheme(val = ColorTheme.Light) {
    await lastValueFrom(
      this.localStorage.setItem(COLOR_THEME_KEY, val, MintStorageType.Local),
    );
    this.layoutState.colorTheme = val;
    this.colorThemeChanged.next(val);
  }

  async autocomplete(addressString: string): Promise<MintPlaceModel[]> {
    return await this.placeService.autocomplete(addressString);
  }

  mapAutocompleteToAddressForm(args: {
    form: MintFormController;
    address: MintAddressModel;
  }) {
    const { form, address } = args;

    form.controls.street.setValue(address.street);
    form.controls.city.setValue(address.city);
    form.controls.stateCode.setValue(address.stateCode);
    form.controls.postalCode.setValue(address.postalCode);
    form.controls.countryCode.setValue(address.countryCode);
  }
}
