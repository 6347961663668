import { Injectable } from '@angular/core';

import {
  MintCacheService,
  MintHttpService,
  MintLogger,
  MintService,
} from '@bryllyant/mint-ngx';
import { TaxpayerService } from '../taxpayer.service';
import { TaxpayerAuthorizationDto } from './taxpayer-authorization.dto';
import { TaxpayerAuthorizationModel } from './taxpayer-authorization.model';

const logger = MintLogger.getLogger('TaxpayerAuthorizationService');

@Injectable({ providedIn: 'root' })
export class TaxpayerAuthorizationService extends MintService<TaxpayerAuthorizationModel> {
  constructor(
    private readonly httpService: MintHttpService,
    private readonly cacheService: MintCacheService,
    private readonly taxpayerService: TaxpayerService,
  ) {
    super(
      httpService,
      cacheService,
      TaxpayerAuthorizationModel,
      TaxpayerAuthorizationModel._mintResourceName,
    );
  }

  async grantAuthorizations(
    dto: TaxpayerAuthorizationDto,
  ): Promise<{ status: 'processing' }> {
    return await this.httpService.post<{ status: 'processing' }>(
      `${this.urlPath}/grant`,
      dto,
    );
  }
}
