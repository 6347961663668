import { plainToInstance } from 'class-transformer';
import {
  IsDefined,
  IsEnum,
  IsInt,
  IsObject,
  IsOptional,
  IsString,
} from 'class-validator';
import { AgentModel } from '../agent/agent.model';
import {
  TaxFormNumber,
  TaxPeriod,
  TranscriptPurpose,
  TranscriptType,
} from '../tax.types';
import { TaxpayerModel } from '../taxpayer/taxpayer.model';

export class TranscriptRequestDto {
  @IsDefined()
  @IsObject()
  taxpayer: Pick<TaxpayerModel, 'uid'>;

  @IsDefined()
  @IsObject()
  agent: Pick<AgentModel, 'uid'>;

  @IsDefined()
  @IsEnum(TaxFormNumber)
  taxFormNumber: TaxFormNumber;

  @IsDefined()
  @IsInt()
  taxYear: number;

  @IsDefined()
  @IsEnum(TaxPeriod)
  taxPeriod: TaxPeriod;

  @IsDefined()
  @IsEnum(TranscriptType)
  transcriptType: TranscriptType;

  @IsDefined()
  @IsEnum(TranscriptPurpose)
  transcriptPurpose: TranscriptPurpose;

  @IsString()
  @IsOptional()
  refCustomerNo?: string;

  constructor(model?: Partial<TranscriptRequestDto>) {
    if (model) {
      Object.assign(this, plainToInstance(TranscriptRequestDto, model));
    }
  }
}
