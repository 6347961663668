<fynvana-modal
  [modalTitle]="title"
  [visible]="isVisible"
  [modalIsLoading]="isLoading"
  [closable]="false"
  [hideCancel]="true"
  (submitted)="closeModal.emit()"
  submitLabel="Ok"
>
  <section class="flex-center flex-col">
    <div class="logo">
      <svg-icon
        src="./assets/common/imgs/logo-vertical.svg"
        svgClass="svg-fluid"
      ></svg-icon>
    </div>

    <div class="mt-2 text-center">
      <p>App Version: {{ versions.frontend }}</p>
      <p>API Version: {{ versions.backend }}</p>
    </div>

    <div class="mt-2 text-center">
      Copyright © {{ currentYear }} Fynvana Inc. All rights reserved.
    </div>
  </section>
</fynvana-modal>
