import {
  MintAccountStatus,
  MintError,
  MintFormFieldOption,
  MintObject,
  MintUserStatus,
} from '@bryllyant/mint-ngx';
import { ClientStatus } from './client/client.types';
import { ClientInvitationStatus } from './client/invitation/client-invitation.types';
import { IrsFormStatus } from './irs-form/irs-form.types';
import { ProjectStatus } from './project/project.types';
import { TaxpayerConsentStatus } from './taxpayer/consent/taxpayer-consent.types';
import { TaxpayerInvitationStatus } from './taxpayer/invitation/taxpayer-invitation.types';
import { TaxpayerType } from './taxpayer/taxpayer.types';

export enum TaxpayerStorageKeys {
  TaxpayerConsentId = 'taxpayer-consent-id',
  TaxpayerHelpMessageViewedMap = 'taxpayer-help-message-viewed-map-key',
  TaxpayerForceAuthorizationError = 'taxpayer-force-authorizations-error',
}

export const taxpayerTypeFilterOptions: MintFormFieldOption[] = [
  { name: 'Individual', value: TaxpayerType.Individual },
  { name: 'Business', value: TaxpayerType.Business },
];

export const taxpayerStatusFilterOptions: MintFormFieldOption[] = [
  { name: 'IRS Form Created', value: IrsFormStatus.FormCreated },
  {
    name: 'IRS Form Pending Signature',
    value: IrsFormStatus.TaxpayerPendingSignature,
  },
  { name: 'IRS Form Signed', value: IrsFormStatus.TaxpayerSigned },
  { name: 'IRS Form Executed', value: IrsFormStatus.FormExecuted },
  { name: 'IRS Form Expired', value: IrsFormStatus.IrsExpired },
  { name: 'IRS Form Submitted', value: IrsFormStatus.IrsSubmitted },
  { name: 'IRS Form Pending', value: IrsFormStatus.IrsPending },
  { name: 'IRS Form Rejected', value: IrsFormStatus.IrsRejected },
  { name: 'IRS Form Accepted', value: IrsFormStatus.IrsAccepted },
];

export const accountStatusFilterOptions: MintFormFieldOption[] = [
  { name: 'Enabled', value: MintAccountStatus.Enabled },
  { name: 'Disabled', value: MintAccountStatus.Disabled },
];

export const projectStatusFilterOptions: MintFormFieldOption[] = [
  { name: 'Active', value: ProjectStatus.Active },
  { name: 'Archived', value: ProjectStatus.Archived },
];

export const clientInvitationStatusFilterOptions: MintFormFieldOption[] = [
  { name: 'Pending', value: ClientInvitationStatus.Pending },
  { name: 'Revoked', value: ClientInvitationStatus.Revoked },
  { name: 'Accepted', value: ClientInvitationStatus.Accepted },
  { name: 'Declined', value: ClientInvitationStatus.Declined },
];

export const taxpayerInvitationStatusFilterOptions: MintFormFieldOption[] = [
  { name: 'Pending', value: TaxpayerInvitationStatus.Pending },
  { name: 'Revoked', value: TaxpayerInvitationStatus.Revoked },
  { name: 'Accepted', value: TaxpayerInvitationStatus.Accepted },
  { name: 'Declined', value: TaxpayerInvitationStatus.Declined },
];

export const clientStatusFilterOptions: MintFormFieldOption[] = [
  { name: 'Enabled', value: ClientStatus.Enabled },
  { name: 'Disabled', value: ClientStatus.Disabled },
];

export const userStatusFilterOptions: MintFormFieldOption[] = [
  { name: 'Enabled', value: MintUserStatus.Enabled },
  { name: 'Disabled', value: MintUserStatus.Disabled },
];

export const transcriptConsentStatusFilterOptions: MintFormFieldOption[] = [
  {
    name: 'Granted',
    value: TaxpayerConsentStatus.TaxpayerGranted,
  },
  {
    name: 'Pending',
    value: TaxpayerConsentStatus.TaxpayerPendingAuthorization,
  },
];

// TODO: reference proper enum values once the ApiModels are created/implemented
export const apiFilterOptions: MintFormFieldOption[] = [
  { name: 'IRS', value: 'irs' },
  { name: 'Docusign', value: 'docusign' },
];

export const FYNVANA_BUSINESS_SLUG = 'fynvana';

export enum ExecutionStatus {
  Queued = 'queued',
  Running = 'running',
  Success = 'success',
  Error = 'error',
}

export enum FynvanaPolicy {
  TaxpayerClientConsent = 'taxpayer-client-consent',
}

export enum FynvanaPortal {
  Website = 'fynvana-web-portal',
  Admin = 'fynvana-admin-portal',
  Developer = 'fynvana-dev-portal',
  Docs = 'fynvana-docs-portal',
  Client = 'fynvana-client-portal',
}

export enum FynvanaRole {
  Client = 'client',
}

export enum TaxIdType {
  SSN = 'ssn', // Social security number
  EIN = 'ein', // Employer identification number
  ITIN = 'itin', // Individual tax ID number
  VAT = 'vat', // International VAT number
}

export enum DocusignFlowType {
  Email = 'email',
  Embedded = 'embedded',
}

export type TypeofValue =
  | 'number'
  | 'string'
  | 'boolean'
  | 'undefined'
  | 'object'
  | 'symbol'
  | 'bigint'
  | 'function';

export interface ModelValidationError {
  key: string;
  original: {
    type: TypeofValue;
    value: any;
    valueTypes: MintObject;
  };

  target: {
    type: TypeofValue;
    value: any;
    valueTypes: MintObject;
  };
}

export const toConsentStatus = (status: TaxpayerConsentStatus): string => {
  switch (status) {
    case TaxpayerConsentStatus.ClientRequested:
      return 'Transcript Requested';

    case TaxpayerConsentStatus.ClientWithdrawn:
      return 'Transcript Request Withdrawn';

    case TaxpayerConsentStatus.ConsentExpired:
      return 'Consent Expired';

    case TaxpayerConsentStatus.ConsentSuperseded:
      return 'Consent Superseded';

    case TaxpayerConsentStatus.IrsPendingActivation:
      return 'IRS Pending';

    case TaxpayerConsentStatus.TaxpayerGranted:
      return 'Granted';

    case TaxpayerConsentStatus.TaxpayerRejected:
      return 'Rejected';

    case TaxpayerConsentStatus.TaxpayerRevoked:
      return 'Consent Revoked';

    case TaxpayerConsentStatus.TaxpayerViewed:
    case TaxpayerConsentStatus.TaxpayerPendingAuthorization:
      return 'Pending eSignature';

    default:
      throw new MintError(`Invalid status: ${status}`);
  }
};
