import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  MintCacheService,
  MintHttpService,
  MintLogger,
  MintObject,
  MintService,
} from '@bryllyant/mint-ngx';
import { TaxpayerInvitationDto } from './taxpayer-invitation.dto';
import { TaxpayerInvitationModel } from './taxpayer-invitation.model';

const logger = MintLogger.getLogger('TaxpayerInvitationService');

@Injectable({ providedIn: 'root' })
export class TaxpayerInvitationService extends MintService<TaxpayerInvitationModel> {
  constructor(
    private readonly httpService: MintHttpService,
    private readonly cacheService: MintCacheService,
  ) {
    super(
      httpService,
      cacheService,
      TaxpayerInvitationModel,
      TaxpayerInvitationModel._mintResourceName,
    );
  }

  async create(
    dto: TaxpayerInvitationDto,
    urlPathVars?: MintObject,
    params?: MintObject,
  ): Promise<TaxpayerInvitationModel> {
    return super.create(dto, urlPathVars, params);
  }

  async getByInvitationCode(
    invitationCode: string,
  ): Promise<TaxpayerInvitationModel | null> {
    try {
      return this.toModel(
        await this.httpService.get<TaxpayerInvitationModel>(
          `${this.urlPath}/${invitationCode}`,
        ),
      );
    } catch (err) {
      if ((err as any)?.status === HttpStatusCode.NotFound) {
        return null;
      }
      throw err;
    }
  }
}
