import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  MintCacheService,
  MintHttpService,
  MintInvitationModel,
  MintLogger,
  MintService,
} from '@bryllyant/mint-ngx';

const logger = MintLogger.getLogger('InvitationService');

@Injectable({ providedIn: 'root' })
export class InvitationService extends MintService<MintInvitationModel> {
  constructor(
    private readonly httpService: MintHttpService,
    private readonly cacheService: MintCacheService,
  ) {
    super(
      httpService,
      cacheService,
      MintInvitationModel,
      MintInvitationModel._mintResourceName,
    );
  }

  async getByInvitationCode(
    invitationCode: string,
  ): Promise<MintInvitationModel | null> {
    try {
      return this.toModel(
        await this.httpService.get<MintInvitationModel>(
          `${this.urlPath}/${invitationCode}`,
        ),
      );
    } catch (err) {
      if ((err as any)?.status === HttpStatusCode.NotFound) {
        return null;
      }
      throw err;
    }
  }
}
