<footer class="footer">
  <!-- Start Minimal Footer -->
  <fynvana-container *ngIf="enableMinimalFooter">
    <section class="footer-minimal">
      <div>
        © {{ currentYear }}
        <a href="https://fynvana.com" target="_blank">Fynvana Inc.</a>
      </div>
      <div>
        <a
          (click)="
            selectedPolicy = MintPolicy.PrivacyPolicy;
            toggleModal('policyModal')
          "
        >
          Privacy Policy
        </a>
        |
        <a
          (click)="
            selectedPolicy = MintPolicy.TermsOfUse; toggleModal('policyModal')
          "
        >
          Terms of Use
        </a>
        |
        <a (click)="toggleModal('aboutModal')">About</a>
      </div>
    </section>
  </fynvana-container>
  <!-- End Minimal Footer -->

  <!-- Start Full Footer -->
  <fynvana-container *ngIf="!enableMinimalFooter">
    <section class="footer-full">
      <!-- Start Logo and Menu -->
      <div nz-row class="footer-row">
        <div nz-col [nzSpan]="24" [nzLg]="12" class="logo-contact-col">
          <div class="logo">
            <a [routerLink]="homeMenuItem.routerLink ?? '/'">
              <svg-icon src="./assets/common/imgs/logo-vertical.svg"></svg-icon>
            </a>
          </div>
        </div>

        <div nz-col [nzSpan]="24" [nzLg]="12" class="footer-menu main-menu-col">
          <nav class="footer-menu-groups">
            <div class="footer-menu-group" *ngFor="let menuItem of menuItems">
              <ng-container *ngIf="!menuItem.children">
                <div class="top-level-menu-item">
                  <a
                    *ngIf="menuItem.url"
                    [href]="menuItem.url"
                    [target]="menuItem.urlTarget ?? '_self'"
                  >
                    {{ menuItem.title | uppercase }}
                  </a>

                  <a
                    *ngIf="menuItem.routerLink"
                    [routerLink]="menuItem.routerLink"
                  >
                    {{ menuItem.title | uppercase }}
                  </a>
                </div>
              </ng-container>

              <ng-container *ngIf="menuItem.children">
                <h1 class="font-primary">{{ menuItem.title }}</h1>
                <ul>
                  <li *ngFor="let menuItem of menuItem.children ?? []">
                    <a
                      *ngIf="menuItem.url"
                      [href]="menuItem.url"
                      [target]="menuItem.urlTarget ?? '_self'"
                    >
                      {{ menuItem.title }}
                    </a>

                    <a
                      *ngIf="menuItem.routerLink"
                      [routerLink]="menuItem.routerLink"
                    >
                      {{ menuItem.title }}
                    </a>
                  </li>
                </ul>
              </ng-container>
            </div>
          </nav>
        </div>
      </div>
      <!-- End Logo and Menu -->

      <!-- Start Copyright and Social Icons -->
      <div nz-row class="footer-row">
        <div nz-col [nzSpan]="24" [nzLg]="12" class="copyright-col">
          <div>
            © {{ currentYear }}
            <a href="https://fynvana.com" target="_blank">Fynvana Inc.</a>
          </div>
          <div>
            <a
              href="{{
                baseService.portalUrls.get(AppIdentifier.Website)
              }}privacy"
            >
              Privacy Policy
            </a>
            |
            <a
              href="{{
                baseService.portalUrls.get(AppIdentifier.Website)
              }}terms"
            >
              Terms of Use
            </a>
            |
            <a (click)="toggleModal('aboutModal')">About</a>
          </div>
        </div>

        <div
          nz-col
          [nzSpan]="24"
          [nzLg]="12"
          class="footer-menu social-links-col"
        >
          <span class="mx-1">Connect with us on</span>
          <nav>
            <ul>
              <li *ngFor="let socialMenuItem of socialMenuItems">
                <a
                  [href]="socialMenuItem.url"
                  [target]="socialMenuItem.urlTarget || '_blank'"
                >
                  <fynvana-icon
                    *ngIf="socialMenuItem.icon"
                    [icon]="socialMenuItem.icon"
                    [height]="20"
                    [width]="20"
                    class="light-action-icon"
                  ></fynvana-icon>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <!-- End Copyright and Social Icons -->
    </section>
  </fynvana-container>
  <!-- End Full Footer -->
</footer>

<fynvana-policy-modal
  *ngIf="modalsVisibleMap.get('policyModal')"
  [isVisible]="modalsVisibleMap.get('policyModal') || false"
  [policyType]="selectedPolicy"
  (handleSuccess)="toggleModal('policyModal')"
  (closeModal)="toggleModal('policyModal')"
  intention="view"
></fynvana-policy-modal>

<fynvana-about-modal
  *ngIf="modalsVisibleMap.get('aboutModal')"
  [isVisible]="modalsVisibleMap.get('aboutModal') || false"
  (closeModal)="toggleModal('aboutModal')"
></fynvana-about-modal>
