import { Injectable } from '@angular/core';
import {
  MintCacheService,
  MintCryptoUtil,
  MintHttpService,
  MintLogger,
  MintObject,
  MintService,
  toJSON,
} from '@bryllyant/mint-ngx';
import { TranscriptRequestDto } from './transcript-request.dto';
import { TranscriptModel } from './transcript.model';

const logger = MintLogger.getLogger('TranscriptService');

@Injectable({ providedIn: 'root' })
export class TranscriptService extends MintService<TranscriptModel> {
  constructor(
    private readonly httpService: MintHttpService,
    private readonly cacheService: MintCacheService,
  ) {
    super(
      httpService,
      cacheService,
      TranscriptModel,
      TranscriptModel._mintResourceName,
    );
  }

  async fetchRawTranscript(
    dto: TranscriptRequestDto,
  ): Promise<{ html: string; object: MintObject }> {
    return await this.httpService.get<{
      html: string;
      object: MintObject;
    }>(`${this.urlPath}/raw`, {
      queryParams: {
        q: MintCryptoUtil.encode('base64', toJSON(dto)),
      },
    });
  }
}
